@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Public Sans', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F9;
  color: #181840;
}

/* width */
::-webkit-scrollbar { width: 4px; height: 8px; }

/* Track */
::-webkit-scrollbar-track { background: #f1f1f1; }
 
/* Handle */
::-webkit-scrollbar-thumb { background: #AAAAB8; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #AAAAB8; }

/* */
.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

/* padding */
.p5 { padding: 5px !important; }
.p-3 { padding: 30px !important; }
.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pb-3 {
  padding-bottom: 30px !important;
}

.pb-4 {
  padding-bottom: 40px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.pb-6 {
  padding-bottom: 60px !important;
}

.pt5 {
  padding-top: 5px !important;
}
.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pt-3 {
  padding-top: 30px !important;
}

.pt-4 {
  padding-top: 40px !important;
}

.pt-5 {
  padding-top: 50px !important;
}

.pt-6 {
  padding-top: 60px !important;
}

.pl5 {
  padding-left: 5px !important;
}
.pl-1 {
  padding-left: 10px !important;
}
.pl15, .pl-15  {
  padding-left: 15px !important;
}
.pl-2 {
  padding-left: 20px !important;
}

.pl-3 {
  padding-left: 30px !important;
}

.pl-4 {
  padding-left: 40px !important;
}

.pl-5 {
  padding-left: 50px !important;
}

.pl-6 {
  padding-left: 60px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.pr-5 {
  padding-right: 50px !important;
}

.pr-6 {
  padding-right: 60px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.mb-6 {
  margin-bottom: 60px !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-6 {
  margin-top: 60px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.ml-6 {
  margin-left: 60px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}


.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.mr-6 {
  margin-right: 60px !important;
}

/* font */
.fs-13 { font-size: 13px !important; }
.fs-14 { font-size: 14px !important; }
.fs-15 { font-size: 15px !important; }
.fs-16 { font-size: 16px !important; }
.fs-17 { font-size: 17px !important; }
.fs-18 { font-size: 18px !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }

 
.twoLineEllip {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  word-break: break-word;
}

.oneLineEllip {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pointer { cursor: pointer; }
.relative { position: relative; }
/* flex */
.dflex {
  display: flex !important;
}

.dinlineflex {
  display: inline-flex !important;
}

.alignCenter {
  align-items: center !important;
}

.spaceBetween {
  justify-content: space-between !important;
}

.justifyCenter {
  justify-content: center !important;
}

.flexEnd {
  justify-content: flex-end !important;
}

.flexStart {
  justify-content: flex-start !important;
}

.flexOne {
  flex: 1 !important;
}

/* Material ui */
.MuiOutlinedInput-root {
  border-radius: 6px !important;
}
.MuiFormHelperText-root.Mui-error {
  font-size: 12px !important;
  color: #d32f2f !important;
  text-transform: none !important;
  text-transform: none !important;
  margin-left: 0px !important;
  padding-bottom: 0px !important;
}
.MuiPickersToolbar-root {
  display: none;
}

.inlineEdit .MuiOutlinedInput-root {
  padding-left: 0px !important;
}

/* .MuiAutocomplete-root .inlineEdit .MuiInputBase-root */
.inlineEdit fieldset {
  border: none  !important;
}

.inlineEdit .MuiAutocomplete-endAdornment {
  visibility: hidden;
}
.inlineEdit.nodata .Mui-disabled .MuiAutocomplete-endAdornment {
  visibility: hidden !important;
}
.inlineEdit.nodata .MuiAutocomplete-endAdornment {
  visibility: visible;
}
.inlineEdit:hover .MuiAutocomplete-endAdornment {
  visibility: visible;
}
.inlineEdit:hover .Mui-disabled .MuiAutocomplete-endAdornment {
  visibility: hidden !important;
}

.inlineEdit .MuiInputAdornment-positionEnd {
  visibility: hidden;
}
.inlineEdit.nodata .MuiInputAdornment-positionEnd {
  visibility: visible;
}
.inlineEdit .MuiInputAdornment-positionEnd .MuiButtonBase-root {
  margin-right: 0;
}
.inlineEdit:hover .MuiInputAdornment-positionEnd, .inlineEdit.adormentVisible .MuiInputAdornment-positionEnd {
  visibility: visible !important;
}
/* .inlineEdit:hover .Mui-disabled .MuiInputAdornment-positionEnd {
  visibility: hidden !important;
} */
.inlineEdit.datePicker .MuiOutlinedInput-root {
  padding-right: 0px;
}

.inlineEdit .MuiOutlinedInput-root {
  padding-right: 0px;
}

.inlineEdit .MuiInputBase-root {
  border: 2px solid transparent  !important;
}
.inlineEdit .Mui-focused.MuiInputBase-root, .inlineEdit:hover .MuiInputBase-root {
  border: 2px solid #2e2e84  !important;
  border-radius: 4px;
}
/* .inlineEdit .Mui-focused.MuiInputBase-root,  */
.inlineEdit:hover .MuiInputBase-root.Mui-disabled {
  border: 2px solid transparent  !important;
  background: transparent !important;
}

.inlineEdit .Mui-focused .MuiInputBase-input, .inlineEdit .MuiOutlinedInput-root:hover .MuiOutlinedInput-input {
  padding-left: 4px !important;
  transition: all 0.5;
}
.inlineEdit .Mui-focused .MuiInputBase-input, .inlineEdit .MuiOutlinedInput-root:hover .MuiOutlinedInput-input.Mui-disabled {
  padding-left: 0px !important;
}

.inlineEdit .MuiOutlinedInput-root:hover {
  background-color: #F5F5F9;
}

.inlineEdit.sm .MuiInputBase-input {
  padding: 5.5px 0px !important;
}

.inlineEdit .MuiInputBase-root.Mui-disabled::before {
  border-bottom-style: none !important;
}
.inlineEdit .MuiInputBase-root.Mui-disabled {
  background: transparent !important;
}

.sm .MuiInputBase-input {
  padding: 5.5px 14px
}
.md .MuiInputBase-input {
  padding: 7.5px 14px
}
.lg .MuiInputBase-input {
  padding: 12.5px 14px
}

.sm .MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
  padding: 0px !important;
  padding-right: 32px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.adorementBlock .MuiInputAdornment-root {
  display: flex !important;
}

.rangeFields {
  height: 35px;
}
.rangeFields .MuiInputBase-root {
  height: 35px;
  border-radius: 4px;
}
.rangeFields.nodata {
  background: #F5F5F9;
  outline: 2px solid transparent !important;
  height: 35px;
}
.rangeFields:hover {
  outline: 2px solid #2e2e84  !important;
  background: #F5F5F9;
  border-radius: 2px;
}
.rangeFields .MuiInputBase-root {
  /* height: 34px; */
  padding-left: 10px;
  /* padding-right: 10px; */
}

.rangeFields .inlineEdit .Mui-focused.MuiInputBase-root, .rangeFields .inlineEdit:hover .MuiInputBase-root {
  border: 2px solid transparent  !important;
}
.rangeFields .inlineEdit .MuiOutlinedInput-root:hover .MuiOutlinedInput-input {
  padding-left: 0px !important;
}
.rangeFields .MuiGrid-root.MuiGrid-item {
  max-width: 45%;
}
.rangeFields .MuiGrid-root.MuiGrid-item input{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableActionIcon {
  padding: 0px;
  margin-right: 10px;
}
.tableActionIcon.Mui-disabled svg path,  .tableActionIcon.Mui-disabled svg .pathFill {
  fill: #ccc;
}
.tableActionIcon.Mui-disabled svg .strokeFill {
  stroke: #ccc;
}

.poperComp {
  width: '500px !important';
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.twoField .MuiInputBase-root:hover {
  min-width: 50px;
}
.twoField .Mui-disabled.MuiInputBase-root:hover {
  min-width: auto !important;
}

@media only screen and (max-width: 1300px) {
  .MuiAutocomplete-clearIndicator {
    display: none !important;
  }
}


.MuiPickersDay-root.Mui-selected {
  color: #fff !important;
}

.MuiButton-containedPrimary:hover {
  box-shadow: 0px 6px 11px #adadad !important;
  background: linear-gradient(66deg, #2E2E84 10%, #8F2F71 100%);
  /* transition: all 1s; */
}
.MuiButton-containedSecondary:hover {
  /* background: linear-gradient(242deg, #DFB233 10%, #CB3066 50%) !important; */
  box-shadow: 3px 5px 11px #adadad !important;
}


.Mui-focusVisible.MuiTab-root {
  outline: 2px solid #222 !important;
}

.MuiIconButton-root {
  pointer-events: auto !important;
}
